import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    // 訪問
    path: "/Login",
    name: "Login",
    component: () => import("../views/login/LoginPage.vue"),
  },
  {
    path: "/Pageview1",
    name: "Pageview1",
    component: () => import("../views/page/InfoToday.vue"),
  },
  {
    path: "/InfoToday2Garph/:id/:sensorId",
    name: "Pageview2",
    component: () => import("../views/page/InfoToday-FullscreenGraph.vue"),
  },
  {
    path: "/Pageview2/:id/:sensorId",
    name: "Pageview2",
    component: () => import("../views/page/InfoToday-2.vue"),
  },
  {
    path: "/HisView1",
    name: "HisView1",
    component: () => import("../views/page/History_1.vue"),
  },
  {
    path: "/HisView2/:id/:sensorId/:starttime/:endtime",
    name: "HisView2",
    component: () => import("../views/page/History_2.vue"),
  },
  {
    path: "/History-2-graph/:id/:sensorId/:starttime/:endtime",
    name: "Fullscreen_History_graph",
    component: () => import("../views/page/History-FullscreenGraph.vue"),
  },
  {
    path: "/DeviceView",
    name: "DeviceView",
    component: () => import("../views/page/Device_Management.vue"),
  },
  {
    path: "/StatView",
    name: "StatView",
    component: () => import("../views/page/StatsView.vue"),
  },
  {
    path: "/",
    redirect: "/Login",
  },
  {
    path: "/app",
    name: "app",
    component: () => import("../views/page/Test_vue__.vue"),
  }
];

const router = new VueRouter({
  routes,
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title =
    toRoute.meta && toRoute.meta.title ? toRoute.meta.title : "溫溼度感測器";

  next();
});

export default router;
