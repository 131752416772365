<template>
  <div class="margin">
    <!-- <index-view></index-view> -->
    <!-- <his-view></his-view> -->
    <!-- <page-view-1></page-view-1> -->
    <!-- <page-view-2></page-view-2> -->
    <router-view></router-view>
  </div>
</template>

<style></style>

<script>
// import PageView1 from './views/page/InfoToday.vue'
// import PageView2 from './views/page/InfoToday-2.vue'
// import IndexView from './views/login/LoginView.vue';
// import HisView from './views/page/HisView.vue';
import "@/assets/main.css";

export default {
  // components: {  /*IndexView*/ /*PageView2*/ HisView },
  data() {
    return {};
  },
};
</script>
